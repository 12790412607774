<template>
	<div id="news">
		<!-- 在线咨询 -->
		<!-- <img class="pos-fixed-img" src="@/assets/logo.png" alt="" /> -->

		 <!-- <headers @scroll="scroll" /> -->

		<img class="banner-img" src="@/assets/logo.png" alt="" />

		<!-- 九大课程列表 -->
		<div class="news-wrap">
			<ul class="nav-wrap flex">
				<li class=" f-0-c-c" @click="toggleNav(i)" :class="[navIndex==i?'active':'']" v-for="(v,i)  in  2" :key="i">
					<img class="li-img" src="@/assets/logo.png" alt="" />
					<span>近期活动</span>
					<div class="pos-abs"></div>
					<img class="pos-abs2" src="@/assets/img/sanjiao.png" alt="" />
				</li>

			</ul>

			<div class="content">
				<div class="item flex">
					<img class="img" src="@/assets/logo.png" alt="" />
					<div class="right-wrap">
						<div class="title hidden1">
							标题名称标题名称标题名称标题名称标题
						</div>
						<div class="text hidden3">
							内容内容内容内容内内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内容内
						</div>
						<div class="btns-wrap clearfix">
							<div class="btns f-c-c">内容详情</div>
						</div>
					</div>
				</div>
			</div>
		</div>



		  <!-- <footers id="footer" /> -->
	</div>
</template>


<script>
	export default {


		data() {
			return {

				navIndex: 0,




			}
		},
		methods: {
			scroll() {
				document.getElementById('footer').scrollIntoView()
			},
			toggleNav(index) {
				if (this.navIndex == index) return

				this.navIndex = index



			},
		}



	};
</script>
<style lang="scss" scoped>
	#news {
		background: #f6f8f5;


		.pos-fixed-img {
			position: fixed;
			right: 0;
			top: 30%;
			width: 1.2rem;
		}

		.banner-img {
			width: 100%;
			height: 5rem;
		}

		.news-wrap {
			padding: 0.63rem 3.6rem;
			box-sizing: border-box;

			transform: translateY(-1.125rem);

			.nav-wrap {
				width: 10rem;
				height: 2.25rem;
				background: #ffffff;
				box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
				border-radius: 0.15rem;
				margin: auto;
				font-size: 0.18rem;

				li {
					width: 50%;
					height: 100%;
					position: relative;

					.pos-abs {
						position: absolute;
						width: 2px;
						height: 1.16rem;
						background: #f2f2f2;
						top: 0;
						bottom: 0;
						right: 0;
						margin: auto;
					}

					.pos-abs2 {
						display: none;
						position: absolute;
						bottom: 0;
						transform: translate(0, 84%);
						left: 0;
						right: 0;
						width: 0.6rem;
						height: 0.4rem;
						margin: auto;
					}

					&.active .pos-abs2 {
						display: block;
					}

					&:last-child(1) {
						display: none;
					}

					.li-img {
						margin: 0.3rem 0 0.26rem 0;
						width: 0.88rem;
						height: 0.88rem;

						span {
							font-size: 0.18rem;
						}
					}
				}
			}

			.home-title {
				margin-bottom: 0.73rem;
			}

			.content {
				width: 10rem;
				margin: 0.4rem auto 0;

				.item {
					width: 100%;
					height: 2.65rem;
					background: #ffffff;
					margin-bottom: 0.14rem;
					padding: 0.25rem 0.3rem 0.26rem 0.44rem;
					box-sizing: border-box;
					position: relative;

					.right-wrap {
						.title {
							font-size: 0.24rem;

							color: #9fd9f6;
							margin-bottom: 0.12rem;
						}

						.text {
							padding-top: 0.21rem;
							box-sizing: border-box;
							border-top: 1px solid #9fd9f6;
							font-size: 0.16rem;
						}

						.btns-wrap {
							position: absolute;
							bottom: 0.3rem;
							right: 0.37rem;
							display: flex;
							flex-direction: row-reverse;

							.btns {
								width: 1.12rem;
								height: 0.32rem;
								background: #fdd23e;
								border-radius: 0.16rem;
								color: #333;
								font-size: 0.16rem;
							}
						}
					}

					.img {
						width: 3.26rem;
						height: 2.14rem;
						background: #d8d8d8;
						margin-right: 0.36rem;
					}
				}
			}
		}
	}
</style>
